
$background: #FFFFFF;
$paper:#FFFFFF;
$surface: #FFFFFF;
//$secondary: #27348A;
$secondary: #3A4D8F;
$red: red;
$greylight: #F2F2F2;
$text-color: #656D78;
$text-secondary: #727272;
$borders: #E3E3E3;
$error: #9E003A;
$success:#0CB262;
$shadow: rgba(0,0,0,0.05);