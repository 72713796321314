@import './variables';
* {
  font-size: 16px;
}
//body {
//  margin: 0;
//  font-family: 'Roboto',sans-serif !important;
//  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//  //  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//  //  sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  font-size: 1em !important;
//  height: 100vh;
//}

#root{
  min-height: 100%;
  background: $background;
}
//
//.MuiButtonBase-root{
//  min-height: 0px !important;
//  border-radius: 4px !important;
//}
// }
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::placeholder {
  color: rgba($color: #000000, $alpha: .8) !important;
  opacity: .8 !important; /* Firefox */
}
.Grow {
  flex-grow: 1
}

.full-width {
  width: 100% !important;
}

.Center {
  text-align: center;
}
.secondary-btn {
  background: $secondary !important;
  color: $surface !important;
}
.success-btn {
  background: $secondary !important;
  color: $surface !important;
}
.primary-btn {
  background: $surface !important;
  color: $text-color !important;
}
.MuiTypography-body1 {
  vertical-align: sub;
  letter-spacing: 0.0em !important;
margin: 0 !important;
font-size: 0.875rem !important;
font-weight: 400 !important;
line-height: 1.334em !important;
font-family: 'Roboto',sans-serif !important;
}
//.h3 {
//  fontSize: 1.25rem !important;
// // color: '#000000',
//  fontWeight: 600;
//}
.MuiListItemText-primary {
  font-family: 'Roboto',sans-serif !important;
  font-weight: 500;
  font-size: 1 rem !important;
  line-height: 1.235;
  color: #3A4D8F !important;
}
.h1 {
  font-family: 'Roboto-Bold' !important ;
  font-size: 1.25rem !important ;
  color: #3A4D8F;
  font-weight: 600;
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.0em !important;
}
.h2 {
  font-family: 'Roboto-Bold' !important ;
  font-size: 1.125rem !important ;
  color: #3A4D8F;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  letter-spacing: 0em !important;
}
.h3 {
  font-family: 'Roboto-Medium' !important ;
  font-size: 1.25rem !important ;
 // color: #3B4754;
  font-weight: 500 !important;
  //font-weight: 400;
  line-height: 1.5 !important;
  letter-spacing: 0.0em !important;
}
.h4 {
  font-family: 'Roboto-Bold' !important ;
  font-size: 1rem !important ;
  color: #ffffff;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.0em !important;
}
//.MuiTypography-h4 {
//  font-family: 'Roboto-Bold' !important ;
//  font-size: 1rem !important ;
//  color: #ffffff;
//  font-weight: 600;
//}
.h5 {
  font-family: 'Roboto' !important ;
   font-size: 0.8rem !important;
   font-weight: 300 !important;
   color: #6A95CC !important;
  padding-top: 0.3rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.0em !important;
  }
.h6 {
  margin: 0 !important ;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.334em !important;
  //font-family: 'Roboto',sans-serif;
 // font-family: 'Roboto' !important;
//padding-top: 8px !important;
 // padding-bottom: 8px !important;
  color: #27348A !important;
}
.weight400 {
  font-weight: 400 !important;
  font-family: 'Roboto' !important;
}
.weight600 {
  font-weight: 600 !important;
  font-family: 'Roboto-Medium' !important;
}
//margin: 0;
//font-size: 0.875rem;
//color: #212121;
//font-weight: 600;
//font-family: 'Roboto',sans-serif;
//line-height: 1.334;
//color: #27348A;
.title {
    font-family: 'Roboto-Bold' !important ;
    font-size: 1.5rem !important ;
    color: #6A95CC !important;
    font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.0em !important;
}
.titleInfo {
  font-family: 'Roboto-Bold' !important ;
  font-size: 0.8rem !important ;
  color: #3A4D8F;
  font-weight: 500;
  line-height: 1.5 !important;
  letter-spacing: 0.0em !important;
}
.titleInfoBig {
  font-family: 'Roboto-Bold' !important ;
  font-size: 1.25rem !important ;
  color: #6A95CC;
  font-weight: 600;
  padding-left: 0.3rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  line-height: 1.5 !important;
  letter-spacing: 0.0em !important;
}
.titleInfoSmall {
  font-family: 'Roboto-Bold' !important ;
  font-size: 0.8rem !important ;
  color: #6A95CC;
  font-weight: 600;
  padding-top: 0.73rem;
  padding-left: 0.3rem;
  padding-bottom: 0.3rem;
  line-height: 1.5 !important;
  letter-spacing: 0.0em !important;
}
.titleRed {
  font-family: 'Roboto-Bold' !important ;
  font-size: 1rem !important ;
  color: #FF8B79;
  font-weight: 500;
  line-height: 1.5 !important;
  letter-spacing: 0.0em !important;

}
.body {
  font-family: 'Roboto' !important;
  color: #656D78;
  font-size: 0.8rem !important;
  font-weight: 300;
  padding-top: 0.05rem;
  line-height: 1.5 !important;
  letter-spacing: 0.0em !important;
}
.grey {
    font-family: 'Roboto-Bold' !important ;
    font-size: 1.125rem !important ;
    color: #656D78;
    font-weight: 600;
  line-height: 1.5 !important;
  letter-spacing: 0.0em !important;
}
.darkGrey {
  color: #3B4754 !important;
 // color: #FF8B79;
}

.MuiIconButton-root{
  padding: 8px !important;
}
.MuiToolBar-root{
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 48px;
  padding: 16px;
}
.MuiFab-root{
  border-radius: 24px !important;
}
.MuiToolbar-regular {
  min-height: 48px !important;
}

//.MuiSwitch-track {
//  height: 100%;
//  width: 100%;
//  border-radius: 7px;
//  z-index: -1;
//  -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//  background-color: #111936 !important;
//  opacity: 0.38;
//}

.makeStyles-label-6 {
  font-family: 'Roboto-Bold' !important;
  font-size: 1.25rem !important;
  font-weight: 600;
}
.MuiPaper-rounded {
  border-radius: 10px;
}
//.MuiSwitch-root {
//  height: 100% !important;
//  width: 100% !important;
//}
//.MuiSwitch-track {
//  height: 100% !important;
//  width: 100% !important;
//  border-radius: 7px !important;
//  z-index: -1;
//  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//  background-color: #111936 !important ;
//  opacity: 0.38 ;
//}